class AdminFormBuilder {
  constructor(formType) {
    this.element = {
      Type: formType,
    };
  }

  withKey(key) {
    this.element.key = key;
    return this;
  }

  withLabel(label) {
    this.element.label = label;
    return this;
  }

  withInitialValue(initialValue) {
    this.element.initialValue = initialValue;
    return this;
  }

  withDefaultValue(defaultValue) {
    this.element.defaultValue = defaultValue;
    return this;
  }

  makeMandatory() {
    this.element.mandatory = true;
    return this;
  }

  withMaxLength(maxLength) {
    this.element.maxLength = maxLength;
    return this;
  }

  makeNumber() {
    this.element.type = "number"
    return this;
  }

  makeMultiline() {
    this.element.multiline = true
    return this;
  }

  withFolder(folder) {
    this.element.folder = folder;
    return this;
  }

  withMultiple() {
    this.element.multiple = true;
    return this;
  }

  withOnDelete(onDelete) {
    this.element.onDelete = onDelete
    return this;
  }

  addItem(value, text) {
    if (this.element.items === undefined) {
      this.element.items = [];
    }
    this.element.items.push({ value, text });
    return this;
  }
  
  build() {
    return this.element;
  }
}

export default AdminFormBuilder;
