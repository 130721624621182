import { Container, Grid, styled } from "@mui/material";
import React from "react";
import { GRADIENT_BACKGROUND } from "utils/constants";
import ProductMiniature from "./ProductMiniature";
import CoverImage from "component/commons/images/CoverImage";
import { bindPicturePath } from "utils/tools";

const ProductRowRoot = styled("div")(({ isEven }) => ({
  padding: "1rem 0",
  background: isEven ? GRADIENT_BACKGROUND : undefined,
}));

export default function ProductsGrid({ data }) {
  return (
    <div>
      {data.map((rowData, index) =>
        rowData.cover ? (
          <CoverImage key={index} src={rowData.cover} />
        ) : (
          <ProductRow key={index} index={index} productRow={rowData} />
        )
      )}
    </div>
  );
}

export function buildProductsGrid(products, grid, min, max) {
  let row = grid[grid.length - 1];
  let nbToReach = min;

  for (let i = 0; i < products.length; ++i) {
    if (row.length === nbToReach) {
      row = [];
      if (nbToReach === max) {
        nbToReach = min;
      } else {
        ++nbToReach;
      }
      grid.push(row);
    }
    row.push(products[i]);
  }

  return grid;
}

function ProductRow({ productRow, index }) {
  return (
    <ProductRowRoot isEven={index % 2}>
      <Container maxWidth="md">
        <Grid
          container
          direction="row"
          justifycontent="center"
          alignItems="center"
          sx={{ minHeight: "90vh" }}
        >
          {productRow.map((product) => (
            <Grid
              item
              key={product.id}
              xs={12}
              sm={12 / productRow.length}
              sx={{ padding: "0 2rem" }}
            >
              <ProductMiniature to={`${product.id}`} name={product.name} src={bindPicturePath("products", product.id, product.first_pic)} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </ProductRowRoot>
  );
}
