import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CircularBackdrop from "component/commons/CircularBackdrop";
import ContainedImage from "component/commons/images/ContainedImage";
import UploadFilesDetails from "component/commons/UploadFilesDetails";
import PromotedItemsUtils, {
  PROMTED_ITEM_TYPES,
} from "component/home/PromotedItemsUtils";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import API from "services/API";
import { sessionTokenState } from "store/atoms";
import {
  greenBackground,
  noDecoration,
  redBackground,
  SelectInputProps,
  whiteColor,
} from "style/style";
import { INSERT_ID } from "utils/constants";

export default function PromotedItemEdit() {
  let { itemId } = useParams();
  let navigate = useNavigate();

  const [item, setItem] = useState(null);
  const [itemType, setItemType] = useState("");
  const [itemCollectId, setItemCollectId] = useState("");
  const [itemCSId, setItemCSId] = useState("");
  const [itemPicture, setItemPicture] = useState(null);
  const [itemPosition, setItemPosition] = useState("0");
  const [isProcessing, setIsProcessing] = useState(false);
  const sessionToken = useRecoilValue(sessionTokenState);

  useEffect(() => {
    if (itemId && itemId !== INSERT_ID) {
      getItem(itemId);
    }
  }, [itemId]);

  const getItem = (item_id) => {
    API.get(`/home/${item_id}`, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    })
      .then(function (response) {
        const item = response.data;
        setUpForm(item);
        setItem(item);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const setUpForm = (item) => {
    setItemType(PromotedItemsUtils.getType(item).id);
    setItemCollectId(item.collect_id);
    setItemCSId(item.custom_section_id);
    setItemPosition(item.position);
  };

  const updateItem = () => {
    setIsProcessing(true);
    API({
      method: itemId === INSERT_ID ? "POST" : "PUT",
      url: "/home",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
      data: {
        id: itemId,
        collect_id:
          itemType === PROMTED_ITEM_TYPES.COLLECT.id ? itemCollectId : null,
        custom_section_id:
          itemType === PROMTED_ITEM_TYPES.CUSTOM_SECTION.id ? itemCSId : null,
        picture: getPictureName(),
        previous_picture: getPreviousPicture(),
        position: itemPosition,
      },
    })
      .then(function (response) {
        const { insertId } = response.data;
        if (itemType === PROMTED_ITEM_TYPES.PICTURE.id && itemPicture) {
          uploadPicture();
        } else if (!insertId) {
          getItem(itemId);
        }
        if (insertId) {
          navigate(insertId, { replace: true });
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setIsProcessing(false);
      });
  };

  const getPictureName = () => {
    if (itemType === PROMTED_ITEM_TYPES.PICTURE.id && itemPicture) {
      return itemPicture.name;
    } else if (
      itemType === PROMTED_ITEM_TYPES.PICTURE.id &&
      item &&
      Boolean(item.picture)
    ) {
      return item.picture;
    }
    return null;
  };

  const getPreviousPicture = () => {
    if (
      item &&
      Boolean(item.picture) &&
      (itemType !== PROMTED_ITEM_TYPES.PICTURE.id ||
        (itemType === PROMTED_ITEM_TYPES.PICTURE.id && itemPicture))
    ) {
      return item.picture;
    } else {
      return null;
    }
  };

  const uploadPicture = () => {
    let imageFormData = new FormData();
    imageFormData.append("promotedItem_cover", itemPicture, itemPicture.name);

    API({
      method: "POST",
      url: "/upload/home",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "Content-Type": "multipart/form-data",
      },
      data: imageFormData,
    }).catch(function (error) {
      console.log(error);
    });
  };

  return (
    <Container
      maxWidth="xl"
      style={{ backgroundColor: "white", color: "black" }}
    >
      <Grid container spacing={3} style={{ width: "100%" }}>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl>
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              labelId="type-label"
              id="type"
              value={itemType}
              onChange={(e) => setItemType(e.target.value)}
              MenuProps={SelectInputProps}
            >
              <MenuItem value={PROMTED_ITEM_TYPES.COLLECT.id}>
                <ListItemText primary={PROMTED_ITEM_TYPES.COLLECT.label} />
              </MenuItem>
              <MenuItem value={PROMTED_ITEM_TYPES.CUSTOM_SECTION.id}>
                <ListItemText
                  primary={PROMTED_ITEM_TYPES.CUSTOM_SECTION.label}
                />
              </MenuItem>
              <MenuItem value={PROMTED_ITEM_TYPES.PICTURE.id}>
                <ListItemText primary={PROMTED_ITEM_TYPES.PICTURE.label} />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {itemType === PROMTED_ITEM_TYPES.COLLECT.id && (
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              color="primary"
              fullWidth={true}
              type="number"
              label={
                "Collection ID" +
                (item && item.collect_id
                  ? ` (current: ${item.collect_name})`
                  : "")
              }
              value={itemCollectId}
              onChange={(e) => setItemCollectId(e.target.value)}
            />
          </Grid>
        )}
        {itemType === PROMTED_ITEM_TYPES.CUSTOM_SECTION.id && (
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              color="primary"
              fullWidth={true}
              type="number"
              label="Custom Section ID"
              value={itemCSId}
              onChange={(e) => setItemCSId(e.target.value)}
            />
          </Grid>
        )}
        {itemType === PROMTED_ITEM_TYPES.PICTURE.id && (
          <Grid item xs={12} md={6} sm={4}>
            <input
              onChange={(e) => setItemPicture(e.target.files[0])}
              accept="image/*"
              style={{ display: "none" }}
              id="picture-upload"
              type="file"
            />
            <label htmlFor="picture-upload">
              <Button variant="contained" color="primary" component="span">
                Set picture
              </Button>
            </label>
            {itemPicture && <UploadFilesDetails files={[itemPicture]} />}
            {item && Boolean(item.picture) && (
              <span>
                <ContainedImage
                  src={PromotedItemsUtils.getPictureFullLink(item.picture)}
                />
              </span>
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            color="primary"
            fullWidth={true}
            type="number"
            label="Position"
            value={itemPosition}
            onChange={(e) => setItemPosition(e.target.value)}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "1em", marginBottom: "1em" }}
        onClick={updateItem}
      >
        {itemId === INSERT_ID ? "Create" : "Update"}
      </Button>
      <CircularBackdrop open={isProcessing} />
    </Container>
  );
}
