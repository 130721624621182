import AddButton from "component/commons/buttons/AddButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { INSERT_ID } from "utils/constants";
import AdminTable from "../commons/AdminTable";
import useBackHistory from "hooks/useBackHistory";
import Toaster, { useToaster } from "component/commons/Toaster";
import useBackendService from "hooks/useBackendService";
import { bindPicturePath, displayPrice } from "utils/tools";
import useQuery from "hooks/useQuery";
import CreationFilters, { filters } from "component/creations/CreationFilters";
import handleError from "../commons/handle-error";

const TABLE_HEADERS = ["ID", "Cover", "Name", "Category", "Collection", "Price"];

export default function CreationTable() {
  const navigate = useNavigate();
  const backHistory = useBackHistory();
  const [apiCall, isLoading] = useBackendService({ withSessionToken: true });
  const query = useQuery();

  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState([]);
  const [creations, setCreations] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [orderBy, setOrderby] = useState();
  const [doneFetching, setDoneFetching] = useState(false);
  const [isOpen, errorMessage, openToaster, closeToaster] = useToaster();

  useEffect(() => {
    getCategories();
    getCollections();
  }, []);

  useEffect(() => {
    reload();
  }, [query]);

  useEffect(() => {
    const order = query.get("order");
    const doReset = order !== orderBy;
    if (doReset) {
      getCreations(doReset ? 0 : creations.length);
    } else {
      applyFilters(creations);
    }
  }, [query, filterText]);

  const reload = () => {
    setDoneFetching(false);
    getCreations(0);
  };

  const getCategories = () => {
    apiCall({
      method: "GET",
      url: "/category",
    })
      .then(function (response) {
        setCategories(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getCollections = () => {
    apiCall({
      method: "GET",
      url: "/collection",
    })
      .then(function (response) {
        setCollections(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getCreations = (offset) => {
    const order = query.get("order");
    apiCall({
      method: "GET",
      url: "/product",
      params: {
        displayAdminFields: true,
        offset,
        order,
      },
    })
      .then((response) => {
        let creationsTmp;
        if (offset === 0) {
          creationsTmp = [];
        } else {
          creationsTmp = [...creations];
        }
        creationsTmp = creationsTmp.concat(response.data);
        setCreations(creationsTmp);
        applyFilters(creationsTmp);
        if (response.data.length === 0) {
          setDoneFetching(true);
        }
        setOrderby(order);
      })
      .catch((error) => {
        openToaster(error.toString());
        console.error(error);
      });
  };

  const applyFilters = (creations) => {
    const categoryFilter = query.get("category");
    const collectionFilter = query.get("collection");
    const genderFilter = query.get("gender");
    let tableData = [];
    filters(creations, categoryFilter, collectionFilter, genderFilter, filterText).forEach(
      (creation) =>
        tableData.push({
          id: creation.id,
          cells: [
            creation.id,
            Boolean(creation.first_pic) &&
              bindPicturePath("products", creation.id, creation.first_pic),
            creation.name,
            creation.category_name,
            creation.collection_name,
            displayPrice(creation.price),
          ],
        })
    );
    setFilteredTableData(tableData);
  };

  const editProduct = (creationId) => {
    navigate(encodeURI(creationId), { ...backHistory });
  };

  const redirectCreation = (creationId) => {
    return `/creations/${creationId}`;
  };

  const deleteProduct = (creationId) => {
    apiCall({
      method: "DELETE",
      url: `/product/${creationId}`,
    })
      .then(() => {
        let tmpData = [...creations];
        let tmpFilteredData = [...filteredTableData];
        setCreations(tmpData.filter((creation) => creation.id !== creationId));
        setFilteredTableData(tmpFilteredData.filter((creation) => creation.id !== creationId));
        reload();
      })
      .catch((error) => handleError(openToaster, error));
  };

  const loadMoreProperties = {
    doneFetching,
    isLoading,
    hasResults: Boolean(creations.length),
    callback: () => getCreations(creations.length),
  };

  return (
    <>
      <CreationFilters categories={categories} collections={collections} onSearch={setFilterText} />
      <AdminTable
        name="creation"
        headers={TABLE_HEADERS}
        rows={filteredTableData}
        redirectAction={redirectCreation}
        editAction={editProduct}
        deleteAction={deleteProduct}
        loadMoreProperties={loadMoreProperties}
      />
      <AddButton onClick={() => editProduct(INSERT_ID)} />
      <Toaster open={isOpen} handleClose={closeToaster} severity="error">
        {errorMessage}
      </Toaster>
    </>
  );
}

// export default function CreationTable() {
//   let navigate = useNavigate();

//   const sessionToken = useRecoilValue(sessionTokenState);

//   const [openModal, setOpenModal] = useState(false);
//   const [modalInfos, setModalInfos] = useState({ product_id: null });

//   const [products, setProducts] = useState([]);
//   const [doneFetching, setDoneFetching] = useState(false);
//   const [search, setSearch] = useState("");

//   useEffect(() => {
//     getProducts(0);
//   }, []);

//   const prepareModal = (productId) => {
//     modalInfos.product_id = productId;
//     setModalInfos(modalInfos);
//     setOpenModal(true);
//   };

//   const processModal = () => {
//     removeProduct(modalInfos.product_id);
//     setOpenModal(false);
//   };

//   const getProducts = (offset) => {
//     API({
//       method: "GET",
//       url: "/product",
//       params: {
//         displayAdminFields: true,
//         offset,
//       },
//       headers: {
//         Authorization: `Bearer ${sessionToken}`,
//       },
//     })
//       .then(function (response) {
//         if (offset === 0) {
//           setProducts(response.data);
//         } else {
//           setProducts([...products].concat(response.data));
//         }

//         if (response.data.length === 0) {
//           setDoneFetching(true);
//         }
//       })
//       .catch(function (error) {
//         console.error(error);
//       });
//   };

//   const removeProduct = (productId) => {
//     API({
//       method: "DELETE",
//       url: `/product/${productId}`,
//       headers: {
//         Authorization: `Bearer ${sessionToken}`,
//       },
//     })
//       .then(function () {
//         const index = products.findIndex((product) => product.id === productId);
//         if (index !== -1) {
//           let productsTmp = [...products];
//           productsTmp.splice(index, 1);
//           setProducts(productsTmp);
//         }
//       })
//       .catch(function (error) {
//         console.error(error);
//       });
//   };

//   return (
//     <div>
//       <Box style={{ backgroundColor: "white" }} p={1}>
//         <TextField
//           label="Search"
//           value={search}
//           onChange={(e) => setSearch(e.target.value)}
//         />
//       </Box>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>ID</TableCell>
//               <TableCell>Cover</TableCell>
//               <TableCell>Name</TableCell>
//               <TableCell>Category</TableCell>
//               <TableCell>Collection</TableCell>
//               <TableCell>Price</TableCell>
//               <TableCell>Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {products.map((product) => (
//               <TableRow
//                 key={product.id}
//                 style={
//                   isSearched(search, product.name) ||
//                   isSearched(search, product.category_name)
//                     ? {}
//                     : { display: "none" }
//                 }
//               >
//                 <TableCell>{product.id}</TableCell>
//                 <TableCell>
//                   <ContainedImage
//                     src={bindPicturePath(
//                       "products/" + product.id + "/",
//                       product.first_pic
//                     )}
//                   />
//                 </TableCell>
//                 <TableCell>{product.name}</TableCell>
//                 <TableCell>{product.category_name}</TableCell>
//                 <TableCell>{product.collect_name}</TableCell>
//                 <TableCell>
//                   {product.price ? displayPrice(product.price) : ""}
//                 </TableCell>
//                 <TableCell>
//                   <Fab
//                     color="primary"
//                     size="small"
//                     onClick={() => navigate(`${product.id}`)}
//                   >
//                     <FontAwesomeIcon icon={faPen} />
//                   </Fab>
//                   <Fab size="small" onClick={() => prepareModal(product.id)}>
//                     <FontAwesomeIcon icon={faTimes} />
//                   </Fab>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       {!doneFetching && (
//         <Container>
//           <ScreenVisibilityDetector
//             onVisible={() => {
//               getProducts(products.length);
//             }}
//           >
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={() => getProducts(products.length)}
//             >
//               Show more
//             </Button>
//           </ScreenVisibilityDetector>
//         </Container>
//       )}

//       <Dialog open={openModal} onClose={() => setOpenModal(false)}>
//         <DialogTitle>Validate your action</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             Are you sure you want to delete this product ?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenModal(false)}>Cancel</Button>
//           <Button onClick={processModal}>Confirm</Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }
