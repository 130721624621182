import { faPen, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddButton from "component/commons/buttons/AddButton";
import ContainedImage from "component/commons/images/ContainedImage";
import PromotedItemsUtils from "component/home/PromotedItemsUtils";
import React, { useEffect, useState } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import API from "services/API";
import { sessionTokenState } from "store/atoms";
import {
  center,
  greenBackground,
  margin,
  mediumListItem,
  noDecoration,
  padding,
  redBackground,
  smallListItem,
  whiteColor,
} from "style/style";
import colors from "style/_colors.scss";
import { INSERT_ID } from "utils/constants";
import { bindPicturePath } from "utils/tools";

export default function PromotedItemsTable() {
  let navigate = useNavigate();
  const sessionToken = useRecoilValue(sessionTokenState);

  const [items, setItems] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [carouselPictures, setCarouselPictures] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [modalInfos, setModalInfos] = useState({
    item_id: null,
    picture: null,
  });

  useEffect(() => {
    if (carouselPictures.length) {
      uploadPicture(carouselPictures);
    } else {
      getItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselPictures]);

  const prepareModal = (item_id, picture) => {
    modalInfos.item_id = item_id;
    modalInfos.picture = picture;
    setModalInfos(modalInfos);
    setOpenModal(true);
  };

  const processModal = () => {
    if (modalInfos.item_id === "picture") {
      deletePicture(modalInfos.picture);
    } else {
      removeItem(modalInfos.item_id, modalInfos.picture);
    }
    setOpenModal(false);
  };

  const getItems = () => {
    API.get("/home")
      .then(function (response) {
        if (response.data) {
          setPictures(response.data.carousel);
          setItems(response.data.items);
        } else {
          console.error("Error while fetching promoted items.");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const uploadPicture = (uploadPics) => {
    let formData = new FormData();
    for (let i = 0; i < uploadPics.length; ++i) {
      formData.append(
        "promotedItem_carousel",
        uploadPics[i],
        uploadPics[i].name
      );
    }

    API({
      method: "POST",
      url: "/upload/home/carousel",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then(function () {
        setCarouselPictures([]);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const removeItem = (item_id, picture) => {
    API({
      method: "DELETE",
      url: `/home/${item_id}`,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
      data: {
        picture: picture,
      },
    })
      .then(function () {
        getItems();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deletePicture = (picture) => {
    API({
      method: "DELETE",
      url: `/upload/home/carousel`,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
      data: {
        picture,
      },
    })
      .then(function () {
        let tmpPics = [...pictures];
        tmpPics.splice(tmpPics.indexOf(picture), 1);
        setPictures(tmpPics);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      <div>
        <h4>Carousel:</h4>
        <ul>
          {pictures.map((pic_path, index) => (
            <li key={index}>
              <ContainedImage
                src={bindPicturePath("promotion/carousel", pic_path)}
              />
              <Fab
                size="small"
                onClick={() => prepareModal("picture", pic_path)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Fab>
            </li>
          ))}
          <li>
            <input
              onChange={(e) => setCarouselPictures(e.target.files)}
              accept="image/*"
              style={{ display: "none" }}
              id="pics-upload"
              type="file"
              multiple
            />
            <label htmlFor="pics-upload">
              <Fab size="small" component="span">
                <FontAwesomeIcon icon={faPlus} />
              </Fab>
            </label>
          </li>
        </ul>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{PromotedItemsUtils.getTypeLabel(item)}</TableCell>
                <TableCell>{PromotedItemsUtils.getName(item)}</TableCell>
                <TableCell>{item.position}</TableCell>
                <TableCell>
                  <Fab
                    size="small"
                    onClick={() => navigate(encodeURI(item.id))}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Fab>
                  <Fab
                    size="small"
                    onClick={() => prepareModal(item.id, item.picture)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Fab>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Validate your action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this item from the home page ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button onClick={processModal}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <AddButton onClick={() => navigate(INSERT_ID)} icon={faPlus} />
    </React.Fragment>
  );
}
